import React from 'react';
import ARTHROPLASTYPIC from '../../Images/service/ARTHROPLASTY PIC.png';
import kneereplacement from '../../Images/service/knee replacement.png';
import HipReplacement from '../../Images/service/Hip Replacement.png';
import Arthroscopy from '../../Images/service/Arthroscopy.png';
import SpineSurgery from '../../Images/service/Spine Surgery.png';
import FractureManagement from '../../Images/service/Fracture Management.png';
import SportInjury from '../../Images/service/Sport Injury.png';
import PainManagement from '../../Images/service/PAIN MANAGEMENT.png';
import { Link } from 'react-router-dom';

const ServiceCard = ({
  image,
  brand,
  serviceName,
  currentPrice,
  originalPrice,
}) => {
  return (
    <div
      data-aos="flip-right"
      data-aos-offset="300"
      data-aos-duration="3000"
      className=" w-80  bg-white shado rounded-xl duration-500 hover:scale-105 hover:shadow-xl"
    >
      <a href="#">
        <img
          src={image}
          alt={serviceName}
          className=" w-full object-cover rounded-t-xl"
        />
        <div className="px-4 py-3">
          <p className="text-lg font-bold text-black truncate block capitalize my-5">
            {serviceName}
          </p>
          <Link to="/services-we-offer">
            {' '}
            <div className="flex justify-center items-center">
              <div className="bg-lightblue rounded-md p-2 w-full text-black hover:bg-blue hover:text-white ">
                Know More
              </div>
            </div>
          </Link>
        </div>
      </a>
    </div>
  );
};

const ServiceCardHome = () => {
  const services = [
    {
      image: ARTHROPLASTYPIC,

      serviceName: 'Arthroplasty',
    },
    {
      image: kneereplacement,

      serviceName: 'knee replacement',
    },
    {
      image: HipReplacement,

      serviceName: 'Hip Replacement',
    },
    {
      image: Arthroscopy,

      serviceName: 'Arthroscopy',
    },
    {
      image: SpineSurgery,

      serviceName: 'Spine Surgery',
    },
    {
      image: FractureManagement,

      serviceName: 'Fracture Management',
    },
    {
      image: SportInjury,

      serviceName: 'Sport Injury',
    },
    {
      image: PainManagement,

      serviceName: 'Pain Management',
    },
  ];

  return (
    <div className="text-center ">
      <section className=" mx-auto grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 justify-items-center justify-center gap-y-20 gap-x-14 mt-10 mb-5">
        {services.map((service, index) => (
          <ServiceCard key={index} {...service} />
        ))}
      </section>
    </div>
  );
};

export default ServiceCardHome;
