import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div class="">
      <div class=" mx-auto px-4 py-8">
        <h1 class="text-3xl font-bold mb-4">Privacy Policy</h1>

        <p class="mb-4">
          This privacy policy sets out how Dr. Ritesh Yadav Orthopedics uses and
          protects any information that you give us when you use this website.
        </p>

        <h2 class="text-2xl font-bold mb-2">Information We Collect</h2>

        <p class="mb-4">We may collect the following information:</p>

        <ul class="list-disc list-inside mb-4">
          <li>Your name and contact information</li>
          <li>Demographic information</li>
          <li>Other information relevant to patient care and services</li>
        </ul>

        <h2 class="text-2xl font-bold mb-2">How We Use the Information</h2>

        <p class="mb-4">
          We require this information to understand your healthcare needs and
          provide you with quality service, including:
        </p>

        <ul class="list-disc list-inside mb-4">
          <li>Internal record keeping and patient management</li>
          <li>Improving our medical care and services</li>
          <li>
            Sending appointment reminders, health updates, or other relevant
            information
          </li>
          <li>Conducting patient satisfaction surveys or clinical research</li>
        </ul>

        <h2 class="text-2xl font-bold mb-2">Security</h2>

        <p class="mb-4">
          We are committed to ensuring that your personal and medical
          information is secure. We have implemented suitable physical,
          electronic, and managerial procedures to safeguard and secure the
          information we collect online or in person.
        </p>

        <h2 class="text-2xl font-bold mb-2">Cookies</h2>

        <p class="mb-4">
          We may use cookies to improve your browsing experience on our website.
          These cookies help us analyze web traffic and customize our website's
          content to better suit your needs. You can choose to accept or decline
          cookies through your browser settings.
        </p>

        <h2 class="text-2xl font-bold mb-2">Links to Other Websites</h2>

        <p class="mb-4">
          Our website may contain links to other websites of interest. However,
          once you leave our site, we do not have control over those external
          sites. Therefore, we cannot be responsible for the protection and
          privacy of any information you provide while visiting such sites.
          Exercise caution and review the privacy policies applicable to those
          websites.
        </p>

        <h2 class="text-2xl font-bold mb-2">
          Controlling Your Personal Information
        </h2>

        <p class="mb-4">
          You have the right to restrict the collection or use of your personal
          information:
        </p>

        <ul class="list-disc list-inside mb-4">
          <li>
            If you have previously agreed to us using your personal information
            for direct communication purposes, you may change your preferences
            at any time by contacting us.
          </li>
          <li>
            We do not sell, distribute, or lease your personal information to
            third parties unless we have your permission or are required by law
            to do so.
          </li>
          <li>
            You may request details of personal information held about you. To
            obtain a copy of this information, please contact us via email at
            [email protected] or write to [Your Company Name, Address, City,
            State, Zip Code].
          </li>
          <li>
            If you believe any information we hold about you is incorrect or
            incomplete, please contact us as soon as possible. We will promptly
            correct any inaccuracies found.
          </li>
        </ul>

        <p class="mb-4">
          This privacy policy is subject to change without notice. Please check
          this page periodically for updates.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
