import React from 'react';
import img1 from '../../Images/DR.RITESH SINGH YADAV KNEE IMAGE.png';
import img2 from '../../Images/final slip disc.png';
const Galleryhome = () => {
  return (
    <div>
      <div class="max-w-screen-2xl mx-auto px-4  relative ">
        <div class="flex flex-col md:flex-row gap-2">
          <div class="flex flex-1 flex-col gap-5">
            <div class="flex flex-1 flex-col" data-aos="fade-down-right">
              <img class="object-cover h-full  rounded-2xl shado" src={img1} />
            </div>
            <div class="hidden md:flex flex-1 flex-row gap-2">
              <div class="flex flex-1 flex-col " data-aos="zoom-in-left">
                <img
                  class="object-cover h-full rounded-2xl shado"
                  src="https://media.istockphoto.com/id/593309612/photo/doctors-do-it-for-the-health-of-it.jpg?s=612x612&w=0&k=20&c=Hac1RSgGUXHM82aNClKDDMJVPcqDmxs6A7WTOVit6gA="
                />
              </div>
              <div
                class="hidden md:flex flex-1 flex-col"
                data-aos="zoom-in-left"
              >
                <img
                  class="object-cover h-full rounded-2xl shado"
                  src="https://media.istockphoto.com/id/1182464971/photo/orthopedic-doctor-checks-patients-range-of-motion.jpg?s=612x612&w=0&k=20&c=AV8pE6rAzrXXI0ejaUV3Fciun9VQkT_EPkjO0mHo1SM="
                />
              </div>
            </div>
          </div>
          <div class="flex flex-1 flex-col gap-5">
            <div class="hidden md:flex flex-1 flex-row gap-5">
              <div class="flex flex-1 flex-col" data-aos="zoom-in-right">
                <img
                  class="object-cover h-full rounded-2xl shado"
                  src="https://media.istockphoto.com/id/1053431248/photo/woman-walking-with-crutches-during-physiotherapy.jpg?s=612x612&w=0&k=20&c=TB5EPoixBHTt_KgHI2ARZ5HoZRT5-3yeQEKHXpP3hnY="
                />
              </div>
              <div
                class="hidden md:flex flex-1 flex-col"
                data-aos="zoom-in-right"
              >
                <img
                  class="object-cover h-full rounded-2xl shado"
                  src="https://media.istockphoto.com/id/1418999473/photo/doctors-comforting-disabled-elderly-patient.jpg?s=612x612&w=0&k=20&c=ggVR5D9U8IY7irIrgvfqSmlLwA7se4vc2fRSAjV2lSs="
                />
              </div>
            </div>
            <div class="flex flex-1 flex-col" data-aos="fade-up-left">
              <img class="object-cover h-full rounded-2xl shado" src={img2} />
            </div>
          </div>
        </div>
      </div>

      {/* <div class="max-w-screen-2xl mx-auto px-4 py-2  relative ">
        <div class="flex flex-col md:flex-row gap-2">
          <div class="flex flex-1 flex-col">
            <div class="flex flex-1 flex-col hover:scale-95">
              <img
                class="object-cover h-full"
                src="https://media.istockphoto.com/id/1133629763/photo/therapist-showing-knee-joint-model.jpg?s=612x612&w=0&k=20&c=-8Wc0qP3mwPlqR-rIGlQX1GnpALhxcvTeOUFcmK5A1g="
                alt=""
              />
            </div>
          </div>
          <div class="flex flex-1">
            <div class="grid grid-cols-2 gap-2">
              <div>
                <img
                  class="object-cover h-full"
                  src="https://media.istockphoto.com/id/1358724980/photo/young-man-doctor-explain-to-the-woman-patient.jpg?s=612x612&w=0&k=20&c=xV9lvRYz1LH8QZyl-Ueuu7aHePJD_z9SDZr4pS27f50="
                  alt=""
                />
              </div>
              <div>
                <img
                  class="object-cover h-full"
                  src="https://media.istockphoto.com/id/512753579/photo/targeting-back-pain.jpg?s=612x612&w=0&k=20&c=iggJ53X9AQvk5W7WKDWheI5ZKGPuXjXYUR_dv1QAy_Y="
                  alt=""
                />
              </div>
              <div>
                <img
                  class="object-cover h-full"
                  src="https://media.istockphoto.com/id/804154084/photo/mid-section-of-man-suffering-with-knee-pain.jpg?s=612x612&w=0&k=20&c=yizRFBtz3UKAZvGdsjgVRfKWj2OgeBfvc_0EX7-iYXU="
                  alt=""
                />
              </div>
              <div>
                <img
                  class="object-cover h-full"
                  src="https://media.istockphoto.com/id/653355740/photo/male-feeling-the-neck-pain.jpg?s=612x612&w=0&k=20&c=LLzTFaaY_htgIqYZQAUmDPbYJFLqTklCvVEfQLhH7oU="
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Galleryhome;
