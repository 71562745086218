import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer class="bg-footer dark:bg-footer">
      <div class="mx-auto w-full max-w-screen-xl">
        <div class="grid grid-cols-2 gap-8 px-4 py-6 lg:py-8 md:grid-cols-3 ">
          <div>
            <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
              Company
            </h2>
            <ul class="text-gray-500 dark:text-gray-400 font-medium">
              <li class="mb-4">
                <Link to="/" class=" hover:underline  hover:text-white">
                  Home
                </Link>
              </li>
              <li class="mb-4">
                <Link to="/about-us" class=" hover:underline  hover:text-white">
                  About us
                </Link>
              </li>
              <li class="mb-4">
                <Link
                  to="/services-we-offer"
                  class="hover:underline hover:text-white"
                >
                  services we offer
                </Link>
              </li>
              <li class="mb-4">
                <Link
                  to="/gallery-withtab"
                  class="hover:underline hover:text-white"
                >
                  Gallery
                </Link>
              </li>

              <li class="mb-4">
                <Link to="/contact-us" class="hover:underline hover:text-white">
                  Contact
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
              Legal
            </h2>
            <ul class="text-gray-500 dark:text-gray-400 font-medium">
              <li class="mb-4">
                <Link
                  to="/privacy-policy"
                  class="hover:underline hover:text-white"
                >
                  Privacy Policy
                </Link>
              </li>

              <li class="mb-4">
                <Link
                  to="/terms-conditions"
                  class="hover:underline hover:text-white"
                >
                  Terms &amp; Conditions
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
              Contact Us
            </h2>
            <ul class="text-gray-500 dark:text-gray-400 font-medium">
              <li class="mb-4">
                <Link to="#" class="hover:underline hover:text-white">
                  Phone: +91 -90740 09424
                </Link>
              </li>
              <li class="mb-4">
                <Link to="#" class="hover:underline hover:text-white">
                  Eamil : drriteshyd83@gmail.com
                </Link>
              </li>
              <li class="mb-4">
                <Link to="#" class="hover:underline hover:text-white">
                  Address: Plot no 07, Aranya, Scheme No 78 - III, opposite
                  Bavdi wale Hanuman mandir Vijay nagar, Indore, Madhya Pradesh
                  452010, Indore, Madhya Pradesh 452018
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div class="px-4 py-6 w-full bg-lightblue dark:bg-lightblue md:flex md:items-center md:justify-between">
          <span class="text-sm text-blue sm:text-center">
            © 2024{' '}
            <Link to="https://r4healthcare.in/" target="_blank">
              R4 Health Care™
            </Link>
            . All Rights Reserved.
          </span>
          <div class="flex mt-4 sm:justify-center md:mt-0 space-x-5 rtl:space-x-reverse">
            <a
              target="_blank"
              href="https://www.facebook.com/drriteshsinghyadavortho"
              class="text-blue bg-white rounded-full p-2 hover:bg-blue  hover:text-white text-xl "
              rel="noreferrer"
            >
              <svg
                class="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 8 19"
              >
                <path
                  fill-rule="evenodd"
                  d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z"
                  clip-rule="evenodd"
                />
              </svg>
              <span class="sr-only">Facebook page</span>
            </a>
            <Link
              to="https://www.instagram.com/dr.riteshsinghyadav/"
              target="_blank"
              className="text-blue bg-white rounded-full p-2 hover:bg-blue hover:text-white text-xl"
            >
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M12 2.2c3.2 0 3.584.012 4.848.07 1.206.056 2.032.247 2.674.527.692.296 1.273.69 1.843 1.26.57.57.963 1.151 1.26 1.843.28.642.471 1.468.527 2.674.058 1.264.07 1.648.07 4.848s-.012 3.584-.07 4.848c-.056 1.206-.247 2.032-.527 2.674-.296.692-.69 1.273-1.26 1.843-.57.57-1.151.963-1.843 1.26-.642.28-1.468.471-2.674.527-1.264.058-1.648.07-4.848.07s-3.584-.012-4.848-.07c-1.206-.056-2.032-.247-2.674-.527-.692-.296-1.273-.69-1.843-1.26-.57-.57-.963-1.151-1.26-1.843-.28-.642-.471-1.468-.527-2.674-.058-1.264-.07-1.648-.07-4.848s.012-3.584.07-4.848c.056-1.206.247-2.032.527-2.674.296-.692.69-1.273 1.26-1.843.57-.57 1.151-.963 1.843-1.26.642-.28 1.468-.471 2.674-.527 1.264-.058 1.648-.07 4.848-.07zM12 0C8.748 0 8.332.014 7.05.072 5.76.13 4.736.332 3.887.673c-.88.358-1.619.848-2.357 1.585C.895 3.996.405 4.735.047 5.615.332 4.736.131 5.76.072 7.05.014 8.332 0 8.748 0 12c0 3.252.014 3.668.072 4.95.058 1.29.26 2.314.61 3.163.358.88.848 1.619 1.585 2.357.738.737 1.477 1.227 2.357 1.585.88.358 1.873.563 3.163.61 1.282.058 1.698.072 4.95.072s3.668-.014 4.95-.072c1.29-.058 2.314-.26 3.163-.61.88-.358 1.619-.848 2.357-1.585.737-.738 1.227-1.477 1.585-2.357.358-.88.563-1.873.61-3.163.058-1.282.072-1.698.072-4.95s-.014-3.668-.072-4.95c-.058-1.29-.26-2.314-.61-3.163-.358-.88-.848-1.619-1.585-2.357-.738-.737-1.477-1.227-2.357-1.585-.88-.358-1.873-.563-3.163-.61C15.668.014 15.252 0 12 0zM12 5.838c-3.41 0-6.162 2.752-6.162 6.162s2.752 6.162 6.162 6.162 6.162-2.752 6.162-6.162S15.41 5.838 12 5.838zM12 16.302c-2.366 0-4.302-1.936-4.302-4.302s1.936-4.302 4.302-4.302 4.302 1.936 4.302 4.302-1.936 4.302-4.302 4.302zM18.405 4.593a1.44 1.44 0 1 1 0-2.88 1.44 1.44 0 0 1 0 2.88z" />
              </svg>
              <span className="sr-only">Instagram</span>
            </Link>

            <Link
              to="#"
              target="_blank"
              className="text-blue bg-white rounded-full p-2 hover:bg-blue hover:text-white text-xl"
            >
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M4.98 3.5c0 1.378-1.11 2.5-2.48 2.5S0 4.878 0 3.5 1.11 1 2.5 1s2.48 1.122 2.48 2.5zM.47 8h4.99V24H.47zM8.39 8h4.78v2.773h.069c.665-1.257 2.287-2.58 4.705-2.58 5.036 0 5.96 3.31 5.96 7.61V24H19.7v-6.98c0-1.675-.03-3.832-2.34-3.832-2.342 0-2.7 1.83-2.7 3.705V24H8.39z" />
              </svg>
              <span className="sr-only">LinkedIn</span>
            </Link>
            <Link
              to="https://www.youtube.com/@Dr.RiteshSinghYadav"
              target="_blank"
              className="text-blue bg-white rounded-full p-2 hover:bg-blue hover:text-white text-xl"
            >
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M23.499 6.203a2.996 2.996 0 0 0-2.111-2.115C19.293 3.444 12 3.444 12 3.444s-7.293 0-9.388.644A2.996 2.996 0 0 0 .501 6.203C0 8.293 0 12 0 12s0 3.707.501 5.797a2.996 2.996 0 0 0 2.111 2.115c2.095.644 9.388.644 9.388.644s7.293 0 9.388-.644a2.996 2.996 0 0 0 2.111-2.115C24 15.707 24 12 24 12s0-3.707-.501-5.797zM9.546 15.568V8.432L15.818 12l-6.272 3.568z" />
              </svg>
              <span className="sr-only">YouTube</span>
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
