import React from 'react';

const Homecardblog = () => {
  const blogData = [
    {
      date: 'June 20, 2024',
      title: 'Understanding Arthroplasty: A Comprehensive Guide',
      image:
        'https://media.istockphoto.com/id/1488353262/photo/human-knee-replacement-surgery-3d-illustration.jpg?s=612x612&w=0&k=20&c=OYnkbRdFzVBGS5PPQU-sDQXVev0zF0zOnbBy5GVynUs=',
      description:
        'Arthroplasty is a surgical procedure that helps restore the function of a joint by resurfacing the bones or replacing it with an artificial implant.',
    },
    {
      date: 'June 22, 2024',
      title: 'Knee Replacement Surgery: What to Expect',
      image:
        'https://media.istockphoto.com/id/1182008411/photo/orthopedic-doctor-showing-model-of-total-knee-replacement-with-blurred-background-of.jpg?s=612x612&w=0&k=20&c=ueD-UktYMDLyB_17ip5YabEKpAtBEp1NuWe13IVq6lI=',
      description:
        'Knee replacement surgery involves replacing a damaged knee joint with a prosthesis to relieve pain and improve mobility, commonly due to arthritis or injury.',
    },
    {
      date: 'June 25, 2024',
      title: 'Effective Pain Management Techniques',
      image:
        'https://media.istockphoto.com/id/1170755336/photo/the-doctor-shows-the-model-of-a-healthy-tooth.jpg?s=612x612&w=0&k=20&c=iS6hw3uMKKDDlUzHmx8w6xf87OWvYJxE6pA8VcBkPA4=',
      description:
        'Pain management includes various approaches such as medications, physical therapy, and minimally invasive procedures to help patients manage chronic and acute pain effectively.',
    },
  ];

  return (
    <div>
      {/* <!-- ====== Blog Section Start --> */}
      <section className=" px-10">
        <div className="container mx-auto">
          <div className="grid md:grid-cols-3 gap-8 ">
            {blogData.map((blog, index) => (
              <div
                key={index}
                className="bg-white shadow rounded-2xl overflow-hidden"
              >
                <div className="mb-8">
                  <img
                    src={blog.image}
                    alt={blog.title}
                    className="w-full h-72 object-cover"
                  />
                </div>
                <div className="p-6">
                  <span className="bg-primary rounded inline-block text-center py-1 px-4 text-xs leading-loose font-semibold text-white mb-5">
                    {blog.date}
                  </span>
                  <h3>
                    <a
                      href="#"
                      className="font-semibold text-xl sm:text-2xl lg:text-xl xl:text-2xl mb-4 inline-block text-dark hover:text-primary"
                    >
                      {blog.title}
                    </a>
                  </h3>
                  <p className="text-base text-body-color">
                    {blog.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* <!-- ====== Blog Section End --> */}
    </div>
  );
};

export default Homecardblog;
