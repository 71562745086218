import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import TopHeader from './TopHeader';
import styled from 'styled-components';
import logo from '../Images/dr.ritesh_yadav_logo-removebg-preview.png';
const NavbarContainer = styled.div`
  position: fixed;
  top: ${({ isFixed }) => (isFixed ? '0' : '50px')};
  width: 100%;
  background: lightblue;
  box-shadow: ${({ isFixed }) =>
    isFixed ? '0 2px 4px rgba(0, 0, 0, 0.1)' : 'none'};
  transition: top 0.3s, box-shadow 0.3s;
  z-index: 1000;

  .navbar-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px;
  }

  .mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 250px;
    height: 100%;
    background: lightblue;
    transform: ${({ isOpen }) =>
      isOpen ? 'translateX(0)' : 'translateX(-100%)'};
    transition: transform 0.3s ease-in-out;
    z-index: 999;
  }

  @media (min-width: 768px) {
    .mobile-menu {
      display: none;
    }
  }
`;
const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isFixed, setIsFixed] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [activeLink, setActiveLink] = useState('');

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Handle scroll event to determine navbar position
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > lastScrollTop) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
      setLastScrollTop(scrollTop);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollTop]);

  // Function to set active link
  const handleSetActiveLink = (link) => {
    setActiveLink(link);
    setIsOpen(false); // Close mobile menu on link click
  };

  return (
    <div className="bg-lightblue w-full">
      <div className="bg-lightblue">
        <TopHeader />
        <NavbarContainer isFixed={isFixed} isOpen={isOpen}>
          <div className="navbar-content font-bold">
            <div className="text-lg font-bold text-blue">
              <img src={logo} alt="" className=" h-12 w-28 object-contain" />
            </div>
            <div className="hidden md:flex">
              <NavLink
                to="/"
                className={`px-4 text-blue hover:text-blue-800 ${
                  activeLink === '/' && 'active'
                }`}
                onClick={() => handleSetActiveLink('/')}
              >
                Home
              </NavLink>
              <NavLink
                to="/about-us"
                className={`px-4 text-blue hover:text-blue-800 ${
                  activeLink === '/about-us' && 'active'
                }`}
                onClick={() => handleSetActiveLink('/about-us')}
              >
                About Us
              </NavLink>
              <NavLink
                to="/services-we-offer"
                className={`px-4 text-blue hover:text-blue-800 ${
                  activeLink === '/services-we-offer' && 'active'
                }`}
                onClick={() => handleSetActiveLink('/services-we-offer')}
              >
                Services we offer
              </NavLink>
              <NavLink
                to="/gallery-withtab"
                className={`px-4 text-blue hover:text-blue-800 ${
                  activeLink === '/gallery-withtab' && 'active'
                }`}
                onClick={() => handleSetActiveLink('/gallery-withtab')}
              >
                Gallery
              </NavLink>
              <NavLink
                to="/contact-us"
                className={`px-4 text-blue hover:text-blue-800 ${
                  activeLink === '/contact-us' && 'active'
                }`}
                onClick={() => handleSetActiveLink('/contact-us')}
              >
                Contact
              </NavLink>
            </div>
            <div className="md:hidden">
              <button
                onClick={toggleMenu}
                className="focus:outline-none text-blue"
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
          <div className="mobile-menu">
            <div className="p-4">
              <button
                onClick={toggleMenu}
                className="focus:outline-none mb-4 text-blue"
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
              <NavLink
                to="/"
                className={`block px-4 py-2 text-blue hover:text-blue-800 ${
                  activeLink === '/' && 'active'
                }`}
                onClick={() => handleSetActiveLink('/')}
              >
                Home
              </NavLink>
              <NavLink
                to="/about-us"
                className={`block px-4 py-2 text-blue hover:text-blue-800 ${
                  activeLink === '/about-us' && 'active'
                }`}
                onClick={() => handleSetActiveLink('/about-us')}
              >
                About Us
              </NavLink>
              <NavLink
                to="/services-we-offer"
                className={`block px-4 py-2 text-blue hover:text-blue-800 ${
                  activeLink === '/services-we-offer' && 'active'
                }`}
                onClick={() => handleSetActiveLink('/services-we-offer')}
              >
                Services we offer
              </NavLink>
              <NavLink
                to="/gallery-withtab"
                className={`block px-4 py-2 text-blue hover:text-blue-800 ${
                  activeLink === '/gallery-withtab' && 'active'
                }`}
                onClick={() => handleSetActiveLink('/gallery-withtab')}
              >
                Gallery
              </NavLink>
              <NavLink
                to="/contact-us"
                className={`block px-4 py-2 text-blue hover:text-blue-800 ${
                  activeLink === '/contact-us' && 'active'
                }`}
                onClick={() => handleSetActiveLink('/contact-us')}
              >
                Contact
              </NavLink>
            </div>
          </div>
        </NavbarContainer>
      </div>
    </div>
  );
};

export default Navbar;
