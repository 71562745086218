import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Hero from '../Pages/LandingPage/Hero';
import Navbar from '../Componets/Navbar';
import Footer from '../Componets/Footer';
import Aboutus from '../Pages/Aboutus/Aboutus';
import ContactForm from '../Pages/Contact/ContactForm';
import Services from '../Pages/Services/Services';
import GalleryWithTab from '../Pages/Gallery/GalleryWithTab';
import PrivacyPolicy from '../Pages/PrivacyPolicy/PrivacyPolicy';
import TermsConditions from '../Pages/TermsConditions/TermsConditions';
import Galleryhome from '../Pages/Gallery/Galleryhome';

const Routing = () => {
  return (
    <div>
      <Navbar />

      <div className="py-20">
        <Routes>
          <Route exact path="/" element={<Hero />} />
          <Route exact path="/about-us" element={<Aboutus />} />
          <Route exact path="/contact-us" element={<ContactForm />} />
          <Route exact path="/services-we-offer" element={<Services />} />
          <Route exact path="/gallery-withtab" element={<Galleryhome />} />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route exact path="/terms-conditions" element={<TermsConditions />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
};

export default Routing;
