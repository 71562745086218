import React from 'react';
import Image from '../../Images/MeniscusSurgery.jpg';

import ARTHROPLASTYPIC from '../../Images/service/ARTHROPLASTY PIC.png';
import kneereplacement from '../../Images/service/knee replacement.png';
import HipReplacement from '../../Images/service/Hip Replacement.png';
import Arthroscopy from '../../Images/service/Arthroscopy.png';
import SpineSurgery from '../../Images/service/Spine Surgery.png';
import FractureManagement from '../../Images/service/Fracture Management.png';
import SportInjury from '../../Images/service/Sport Injury.png';
import PainManagement from '../../Images/service/PAIN MANAGEMENT.png';
const Services = () => {
  return (
    <div>
      <section class="w-full mx-auto py-10 ">
        {/* <!--ARTHROPLASTYPIC--> */}
        <div class="xl:w-[80%] sm:w-[90%] mx-auto flex flex-col md:flex-row gap-4 items-stretch mt-4 ">
          <div class="lg:w-[50%] md:w-full" data-aos="fade-right">
            <img
              class="shado rounded-lg"
              src={ARTHROPLASTYPIC}
              alt="ACL Reconstruction Image"
            />
          </div>
          <div
            class="lg:w-[50%] md:w-full bg-white shado p-4 rounded-md"
            data-aos="fade-left"
          >
            <h2 class="text-3xl font-semibold text-gray-900  underline underline-offset-8 ">
              ARTHROPLASTY
            </h2>
            <p class="text-md mt-4">
              Arthroplasty is a surgical procedure to restore the function of a
              joint. This is done by resurfacing the bones or replacing the
              joint with an artificial implant. Commonly performed on hips and
              knees, arthroplasty aims to relieve pain and improve mobility,
              especially for patients suffering from arthritis or significant
              joint damage. Our advanced techniques and personalized care ensure
              optimal outcomes and faster recovery times.
            </p>
          </div>
        </div>
        {/* <!--kneereplacement--> */}
        <div class="xl:w-[80%] sm:w-[90%] mx-auto flex flex-col md:flex-row gap-4 items-stretch mt-4 ">
          <div
            class="lg:w-[50%] md:w-full bg-white shado p-4 rounded-md"
            data-aos="fade-right"
          >
            <h2 class="text-3xl font-semibold text-gray-900  underline underline-offset-8 ">
              knee replacement
            </h2>
            <p class="text-md mt-4">
              Knee Replacement surgery involves replacing a damaged or worn-out
              knee joint with a prosthesis. This procedure is typically
              recommended for patients with severe knee pain and dysfunction due
              to arthritis or injury. Our team utilizes the latest in surgical
              technology and postoperative care to enhance the quality of life,
              offering both partial and total knee replacement options based on
              individual patient needs.
            </p>
          </div>
          <div class="lg:w-[50%] md:w-full" data-aos="fade-left">
            <img
              class="shado rounded-lg"
              src={kneereplacement}
              alt="ACL Reconstruction Image"
            />
          </div>
        </div>
        {/* <!--HipReplacement--> */}
        <div class="xl:w-[80%] sm:w-[90%] mx-auto flex flex-col md:flex-row gap-4 items-stretch mt-4 ">
          <div class="lg:w-[50%] md:w-full" data-aos="fade-right">
            <img
              class="shado rounded-lg"
              src={HipReplacement}
              alt="ACL Reconstruction Image"
            />
          </div>
          <div
            class="lg:w-[50%] md:w-full bg-white shado p-4 rounded-md"
            data-aos="fade-left"
          >
            <h2 class="text-3xl font-semibold text-gray-900  underline underline-offset-8 ">
              Hip Replacement
            </h2>
            <p class="text-md mt-4">
              Hip Replacement surgery replaces the hip joint with an artificial
              one, usually due to severe arthritis or injury. This procedure can
              significantly reduce pain and restore mobility. Dr. Ritesh Yadav’s
              expertise in minimally invasive techniques ensures reduced
              recovery times and improved outcomes, allowing patients to return
              to their daily activities with greater ease and comfort.
            </p>
          </div>
        </div>
        {/* <!--Arthroscopy--> */}
        <div class="xl:w-[80%] sm:w-[90%] mx-auto flex flex-col md:flex-row gap-4 items-stretch mt-4 ">
          <div
            class="lg:w-[50%] md:w-full bg-white shado p-4 rounded-md"
            data-aos="fade-right"
          >
            <h2 class="text-3xl font-semibold text-gray-900  underline underline-offset-8 ">
              Arthroscopy
            </h2>
            <p class="text-md mt-4">
              Arthroscopy is a minimally invasive surgical procedure used to
              diagnose and treat joint problems. By inserting a small camera
              into the joint, we can visualize, diagnose, and treat issues
              within the knee, shoulder, elbow, wrist, ankle, and hip joints.
              This technique offers quicker recovery times, less pain, and
              minimal scarring compared to traditional open surgery.
            </p>
          </div>
          <div class="lg:w-[50%] md:w-full" data-aos="fade-left">
            <img
              class="shado rounded-lg"
              src={Arthroscopy}
              alt="ACL Reconstruction Image"
            />
          </div>
        </div>
        {/* <!--SpineSurgery--> */}
        <div class="xl:w-[80%] sm:w-[90%] mx-auto flex flex-col md:flex-row gap-4 items-stretch mt-4 ">
          <div class="lg:w-[50%] md:w-full" data-aos="fade-right">
            <img
              class="shado rounded-lg"
              src={SpineSurgery}
              alt="ACL Reconstruction Image"
            />
          </div>
          <div
            class="lg:w-[50%] md:w-full bg-white shado p-4 rounded-md"
            data-aos="fade-left"
          >
            <h2 class="text-3xl font-semibold text-gray-900  underline underline-offset-8 ">
              Spine Surgery
            </h2>
            <p class="text-md mt-4">
              Spine Surgery addresses a variety of spinal issues, including
              herniated discs, spinal stenosis, and degenerative disc disease.
              Our goal is to relieve pain, restore function, and improve quality
              of life through both minimally invasive and traditional surgical
              techniques. Each patient receives a customized treatment plan to
              address their specific spinal condition and overall health.
            </p>
          </div>
        </div>
        {/* <!--FractureManagement--> */}
        <div class="xl:w-[80%] sm:w-[90%] mx-auto flex flex-col md:flex-row gap-4 items-stretch mt-4 ">
          <div
            class="lg:w-[50%] md:w-full bg-white shado p-4 rounded-md"
            data-aos="fade-right"
          >
            <h2 class="text-3xl font-semibold text-gray-900  underline underline-offset-8 ">
              Fracture Management
            </h2>
            <p class="text-md mt-4">
              Fracture Management involves the diagnosis, treatment, and
              rehabilitation of broken bones. We utilize advanced imaging and
              surgical techniques to ensure proper alignment and healing of
              fractures. Our comprehensive approach includes pain management,
              physical therapy, and continuous monitoring to promote optimal
              recovery and prevent future complications.
            </p>
          </div>{' '}
          <div class="lg:w-[50%] md:w-full" data-aos="fade-left">
            <img
              class="shado rounded-lg"
              src={FractureManagement}
              alt="ACL Reconstruction Image"
            />
          </div>
        </div>
        {/* <!--SportInjury--> */}
        <div class="xl:w-[80%] sm:w-[90%] mx-auto flex flex-col md:flex-row gap-4 items-stretch mt-4 ">
          <div class="lg:w-[50%] md:w-full" data-aos="fade-right">
            <img
              class="shado rounded-lg"
              src={SportInjury}
              alt="ACL Reconstruction Image"
            />
          </div>
          <div
            class="lg:w-[50%] md:w-full bg-white shado p-4 rounded-md"
            data-aos="fade-left"
          >
            <h2 class="text-3xl font-semibold text-gray-900  underline underline-offset-8 ">
              Sport Injury
            </h2>
            <p class="text-md mt-4">
              Sport Injury treatment covers a wide range of injuries sustained
              during athletic activities, including sprains, strains, ligament
              tears, and fractures. We provide tailored treatment plans that
              focus on quick recovery and prevention of future injuries. Our
              services include surgical and non-surgical options, rehabilitation
              programs, and performance enhancement strategies to help athletes
              return to their sport at peak condition.
            </p>
          </div>
        </div>
        {/* <!--PainManagement--> */}
        <div class="xl:w-[80%] sm:w-[90%] mx-auto flex flex-col md:flex-row gap-4 items-stretch mt-4 ">
          <div
            class="lg:w-[50%] md:w-full bg-white shado p-4 rounded-md"
            data-aos="fade-right"
          >
            <h2 class="text-3xl font-semibold text-gray-900  underline underline-offset-8 ">
              Pain Management
            </h2>
            <p class="text-md mt-4">
              Pain Management involves a multidisciplinary approach to treat
              chronic and acute pain. Our team specializes in diagnosing the
              underlying causes of pain and offering various treatment options,
              including medications, physical therapy, injections, and minimally
              invasive procedures. We aim to improve patients’ quality of life
              by effectively managing and reducing pain levels, allowing them to
              engage in daily activities with greater comfort.
            </p>
          </div>
          <div class="lg:w-[50%] md:w-full" data-aos="fade-left">
            <img
              class="shado rounded-lg"
              src={PainManagement}
              alt="ACL Reconstruction Image"
            />
          </div>
        </div>

        {/* <!-- ACL Reconstruction --> */}
        <div class="xl:w-[80%] sm:w-[90%] mx-auto flex flex-col md:flex-row gap-4 items-stretch mt-4 ">
          <div class="lg:w-[50%] md:w-full" data-aos="fade-right">
            <img
              class="shado rounded-lg"
              src="https://media.istockphoto.com/id/1170867537/photo/asian-woman-patient-with-bandage-compression-knee-brace-support-injury-on-the-bed-in-nursing.jpg?s=612x612&w=0&k=20&c=Ne8lOvhH8WOlEKAfBCqvqW26bZc5ELkDFxL0uzX-ZvU="
              alt="ACL Reconstruction Image"
            />
          </div>
          <div
            class="lg:w-[50%] md:w-full bg-white shado p-4 rounded-md"
            data-aos="fade-left"
          >
            <h2 class="text-3xl font-semibold text-gray-900  underline underline-offset-8 ">
              ACL Reconstruction
            </h2>
            <p class="text-md mt-4">
              ACL reconstruction is a surgical procedure aimed at repairing a
              torn anterior cruciate ligament (ACL), a crucial ligament in the
              knee joint. Commonly injured during sports activities that involve
              sudden stops or changes in direction, like soccer and basketball,
              ACL tears can significantly affect knee stability. Surgery
              involves replacing the damaged ligament with a graft, either from
              the patient's own tissue (autograft) or from a donor (allograft),
              to restore knee function and prevent further instability.
            </p>
          </div>
        </div>

        {/* <!-- Ligament Surgery --> */}
        <div class="xl:w-[80%] sm:w-[90%] mx-auto flex flex-col md:flex-row gap-4 items-stretch mt-6">
          <div
            class="lg:w-[50%] md:w-full bg-white shado p-4 rounded-md"
            data-aos="fade-right"
          >
            <h2 class="text-3xl font-semibold text-gray-900 underline underline-offset-8 ">
              Ligament Surgery
            </h2>
            <p class="text-md mt-4">
              Ligament surgery is performed to repair or reconstruct damaged
              ligaments, often in the knee joint. Ligaments are tough bands of
              tissue that connect bones and stabilize joints. When a ligament is
              severely torn or ruptured due to injury or trauma, surgery may be
              necessary to restore joint stability. This involves using a piece
              of tendon (graft) to replace the torn ligament and promote
              healing. Ligament surgeries are essential in restoring normal
              joint function and preventing long-term complications.
            </p>
          </div>
          <div class="lg:w-[50%] md:w-full" data-aos="fade-left">
            <img
              class="shado rounded-lg"
              src="https://media.istockphoto.com/id/1272207619/photo/woman-suffering-from-pain-in-knee.jpg?s=612x612&w=0&k=20&c=BmHqBnCb4XlrJnLFIlw3pYhUp-IsxbK9nRoj4kYI3sU="
              alt="Ligament Surgery Image"
            />
          </div>
        </div>

        {/* <!-- Meniscus Surgery --> */}
        <div class="xl:w-[80%] sm:w-[90%] mx-auto flex flex-col md:flex-row gap-4 items-stretch mt-6">
          <div class="lg:w-[50%] md:w-full" data-aos="fade-right">
            <img
              class="shado rounded-lg"
              src="https://media.istockphoto.com/id/1156927791/photo/knieschmerz-conceptual-artwork-3d-illustration.jpg?s=612x612&w=0&k=20&c=xO7Z1ebjF-xQHXbpbeQB1QlKP8tzZMgR6KQbrK24Rk8="
              alt="Meniscus Surgery Image"
            />
          </div>
          <div
            class="lg:w-[50%] md:w-full bg-white shado p-4 rounded-md"
            data-aos="fade-left"
          >
            <h2 class="text-3xl font-semibold text-gray-900 underline underline-offset-8 ">
              Meniscus Surgery
            </h2>
            <p class="text-md mt-4">
              Meniscus surgery addresses tears or injuries to the meniscus, a
              cartilage cushion in the knee joint. The meniscus can tear due to
              sudden twisting or repetitive stress, causing pain, swelling, and
              limited movement. Surgical options include trimming the torn part
              of the meniscus (meniscectomy) or repairing it using sutures or
              anchors. Recovery involves physical therapy to strengthen the knee
              and restore full range of motion, typically lasting a few weeks to
              months depending on the severity of the injury.
            </p>
          </div>
        </div>

        {/* <!-- Knee Fracture Surgery --> */}
        <div class="xl:w-[80%] sm:w-[90%] mx-auto flex flex-col md:flex-row gap-4 items-stretch mt-6">
          <div
            class="lg:w-[50%] md:w-full bg-white shado p-4 rounded-md"
            data-aos="fade-right"
          >
            <h2 class="text-3xl font-semibold text-gray-900 underline underline-offset-8 ">
              Knee Fracture Surgery
            </h2>
            <p class="text-md mt-4">
              Knee fracture surgery involves treating fractures or breaks in the
              kneecap (patella) or other parts of the knee joint. Depending on
              the severity and location of the fracture, surgical options may
              include open reduction and internal fixation (ORIF), where metal
              plates, screws, or wires are used to stabilize the fractured
              bones. This procedure aims to align the bones properly, facilitate
              healing, and restore knee function through rehabilitation and
              physical therapy.
            </p>
          </div>
          <div class="lg:w-[50%] md:w-full" data-aos="fade-left">
            <img
              class="shado rounded-lg"
              src="https://media.istockphoto.com/id/172349513/photo/after-the-meniscus-operation.jpg?s=612x612&w=0&k=20&c=deDt0Lz9n5PYcaWsw7nxtauPaeu4yn3GAoaaxCUjRUk="
              alt="Knee Fracture Surgery Image"
            />
          </div>
        </div>

        {/* <!-- Hip Fracture Surgery --> */}
        <div class="xl:w-[80%] sm:w-[90%] mx-auto flex flex-col md:flex-row gap-4 items-stretch mt-6">
          <div class="lg:w-[50%] md:w-full" data-aos="fade-right">
            <img
              class="shado rounded-lg"
              src="https://media.istockphoto.com/id/1992765460/photo/x-ray-of-the-human-pelvis-with-both-hip-joints-ap-view.jpg?s=612x612&w=0&k=20&c=CK2VLpFsNKqYRx5RlTXasvRn24bdeGNN-us51gJhF8g="
              alt="Hip Fracture Surgery Image"
            />
          </div>
          <div
            class="lg:w-[50%] md:w-full bg-white shado p-4 rounded-md"
            data-aos="fade-left"
          >
            <h2 class="text-3xl font-semibold text-gray-900 underline underline-offset-8 ">
              Hip Fracture Surgery
            </h2>
            <p class="text-md mt-4">
              Hip fracture surgery, also known as hip pinning or internal
              fixation, is performed to repair a broken hip bone (femur). This
              procedure involves securing the fractured bones with metal screws,
              plates, or nails to stabilize the hip joint and promote healing.
              Hip fractures often occur in older adults due to falls or
              osteoporosis. Surgery is crucial for restoring mobility and
              preventing complications such as arthritis and decreased
              independence.
            </p>
          </div>
        </div>

        {/* <!-- Shoulder Surgery --> */}
        <div class="xl:w-[80%] sm:w-[90%] mx-auto flex flex-col md:flex-row gap-4 items-stretch mt-6">
          <div
            class="lg:w-[50%] md:w-full bg-white shado p-4 rounded-md"
            data-aos="fade-right"
          >
            <h2 class="text-3xl font-semibold text-gray-900 underline underline-offset-8 ">
              Shoulder Surgery
            </h2>
            <p class="text-md mt-4">
              Shoulder surgery encompasses various procedures aimed at treating
              shoulder injuries and conditions affecting the shoulder joint.
              Common surgeries include rotator cuff repair for tears in the
              shoulder tendons, shoulder stabilization for dislocations, and
              shoulder replacement for severe arthritis. These surgeries help
              alleviate pain, improve shoulder function, and restore range of
              motion, often accompanied by rehabilitation to strengthen the
              shoulder muscles and ligaments.
            </p>
          </div>
          <div class="lg:w-[50%] md:w-full" data-aos="fade-left">
            <img
              class="shado rounded-lg"
              src="https://media.istockphoto.com/id/178540512/photo/doctor-examining-elderly-male-patient-with-pain-in-shoulder.jpg?s=612x612&w=0&k=20&c=2Hr0hfe8Rdiz1mFWT_i4_0JKwa6-f5UvNkRmrIQA6eA="
              alt="Shoulder Surgery Image"
            />
          </div>
        </div>

        {/* <!-- Ligament Sprain --> */}
        <div class="xl:w-[80%] sm:w-[90%] mx-auto flex flex-col md:flex-row gap-4 items-stretch mt-6">
          <div class="lg:w-[50%] md:w-full" data-aos="fade-right">
            <img
              class="shado rounded-lg"
              src="https://media.istockphoto.com/id/688144148/photo/sportswoman-with-injured-ankle.jpg?s=612x612&w=0&k=20&c=JrwGi3h95NFV32dwMfrYveQ0d4K0XVjJPOoXSTlf49w="
              alt="Ligament Sprain Image"
            />
          </div>
          <div
            class="lg:w-[50%] md:w-full bg-white shado p-4 rounded-md"
            data-aos="fade-left"
          >
            <h2 class="text-3xl font-semibold text-gray-900 underline underline-offset-8 ">
              Ligament Sprain
            </h2>
            <p class="text-md mt-4">
              A ligament sprain occurs when a ligament is stretched or torn due
              to sudden trauma or overuse. Common examples include ankle sprains
              and knee sprains, which can cause pain, swelling, and instability
              in the affected joint. Treatment involves rest, ice, compression,
              and elevation (RICE), followed by physical therapy to regain
              strength and flexibility. Severe sprains may require medical
              evaluation to rule out ligament tears or fractures.
            </p>
          </div>
        </div>

        {/* <!-- Low Back Pain --> */}
        <div class="xl:w-[80%] sm:w-[90%] mx-auto flex flex-col md:flex-row gap-4 items-stretch mt-6">
          <div
            class="lg:w-[50%] md:w-full bg-white shado p-4 rounded-md"
            data-aos="fade-right"
          >
            <h2 class="text-3xl font-semibold text-gray-900 underline underline-offset-8 ">
              Low Back Pain
            </h2>
            <p class="text-md mt-4">
              Low back pain is a common condition characterized by discomfort in
              the lower part of the spine. Causes include muscle strains,
              ligament sprains, herniated discs, arthritis, and spinal
              conditions. Treatment options range from conservative measures
              such as rest, heat or ice therapy, and pain medications to
              physical therapy, spinal injections, and in severe cases, surgery.
              Managing low back pain focuses on alleviating symptoms and
              improving quality of life.
            </p>
          </div>
          <div class="lg:w-[50%] md:w-full" data-aos="fade-left">
            <img
              class="shado rounded-lg"
              src="https://media.istockphoto.com/id/1073755868/photo/lower-back-pain-medical-examination.webp?b=1&s=170667a&w=0&k=20&c=K1auUYwogFM_h_68bYASQxLma7VAEZP7axJZXuki7ag="
              alt="Low Back Pain Image"
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Services;
