import logo from './logo.svg';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import Routing from './Routings/Routing';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
function App() {
  useEffect(() => {
    AOS.init({
      duration: 2000, // Duration of the animation in milliseconds
    });
  }, []);
  return (
    <div className="bg-gray-200 ">
      <BrowserRouter>
        <Routing />
        {/* <Navbar /> */}
      </BrowserRouter>
    </div>
  );
}

export default App;
