import React from 'react';
import { IoMdAdd } from 'react-icons/io';
import image1 from '../../Images/dr.ritesh yadav sir pic.jpg';
import { Link } from 'react-router-dom';
const Homeabout = () => {
  return (
    <div>
      <section class="">
        <div class="container mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <div class="grid grid-cols-1 md:grid-cols-2 items-center gap-8">
            <div class="max-w-lg">
              <div className="flex justify-center items-center ">
                <h2 className="text-center text-3xl flex justify-center flex-col font-semibold mb-5">
                  About Us
                  <div className="flex justify-center items-center text-primary py-3">
                    <div className="w-20 border-b-2 border-blue"></div>
                    <i className="fa-solid fa-circle text-[8px] text-blue"></i>
                    <div className="w-20 border-b-2 border-blue"></div>
                  </div>
                </h2>
              </div>
              <p class="mt-4 text-gray-600 text-lg text-justify">
                "Dr. Ritesh Singh Yadav is a renowned, Experienced orthopedic
                surgeon in Indore. He did his MBBS from the prestigious G.R.
                Medical collage, Gwalior and than completed his M.S.
                (Orthopaedics) from the M.G.M. Medical Collage, Indore (M.P.).
                In present time Dr. Ritesh Singh Yadav is performing orthopaedic
                surgeries in several multispeciality hospital in indore as a
                orthopaedic surgeon and consultant. He has vast experience in
                handling total knee replacement (TKR), total hip replacement
                (THR), arthroscopy (knee, shoulder) including ACL
                Reconstruction, Bankart repair, MCL, PCL Meniscus repair. He is
                also Handling complex trauma, fractures, spine surgeries and
                pain management. He had performed more than 50000 orthopaedic
                surgeries."
              </p>
              <div class="mt-8">
                <Link
                  to="/about-us"
                  class="text-blue hover:text-blue font-medium"
                >
                  Learn more about us
                  <span class="ml-2">&#8594;</span>
                </Link>
              </div>
            </div>
            <div class="mt-12 md:mt-0">
              <img
                src={image1}
                alt="About Us Image"
                class="object-cover rounded-lg shadow-md"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Homeabout;
