import { useState } from 'react';
import './ContactForm.css';

const ContactForm = () => {
  const [focus, setFocus] = useState({});

  const handleFocus = (e) => {
    setFocus({ ...focus, [e.target.name]: true });
  };

  const handleBlur = (e) => {
    if (e.target.value === '') {
      setFocus({ ...focus, [e.target.name]: false });
    }
  };

  return (
    <div className="container">
      <span className="big-circle"></span>
      <img src="img/shape.png" className="square" alt="" />
      <div className="form">
        <div className="contact-info">
          <h3 className="title">Let's get in touch</h3>
          <p className="text text-justify">
            "Let's connect and explore opportunities together. Reach out today
            to start the conversation."
          </p>

          <div className="info">
            <div className="information ">
              <i className="fas fa-map-marker-alt text-xl"></i>&nbsp;&nbsp;
              <p className="mt-5 text-justify">
                Plot no 07, Aranya, Scheme No 78 - III, Vijay nagar, Indore,
              </p>
            </div>
            <div className="information">
              <i className="fas fa-envelope"></i>&nbsp;&nbsp;
              <p>drriteshyd83@gmail.com </p>
            </div>
            <div className="information">
              <i className="fas fa-phone"></i>&nbsp;&nbsp;
              <p>90740 09424</p>
            </div>
          </div>

          <div className="social-media">
            {/* <p>Connect with us :</p> */}
            <div className="social-icons">
              <a target='_blank' href="https://www.facebook.com/drriteshsinghyadavortho">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="#">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="#">
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div>
        </div>

        <div className="contact-form">
          <span className="circle one"></span>
          <span className="circle two"></span>

          <form action="index.html" autoComplete="off">
            <h3 className="title">Contact us</h3>
            <div className="input-container">
              <input
                type="text"
                name="name"
                className={`input ${focus.name ? 'focus' : ''}`}
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
              <label htmlFor="">Username</label>
              <span>Username</span>
            </div>
            <div className="input-container">
              <input
                type="email"
                name="email"
                className={`input ${focus.email ? 'focus' : ''}`}
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
              <label htmlFor="">Email</label>
              <span>Email</span>
            </div>
            <div className="input-container">
              <input
                type="tel"
                name="phone"
                className={`input ${focus.phone ? 'focus' : ''}`}
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
              <label htmlFor="">Phone</label>
              <span>Phone</span>
            </div>
            <div className="input-container textarea">
              <textarea
                name="message"
                className={`input ${focus.message ? 'focus' : ''}`}
                onFocus={handleFocus}
                onBlur={handleBlur}
              ></textarea>
              <label htmlFor="">Message</label>
              <span>Message</span>
            </div>
            <input type="submit" value="Send" className="btn" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
