import React from 'react';
import styled from 'styled-components';
import { FaEnvelope, FaMapMarkerAlt, FaPhoneAlt } from 'react-icons/fa';

const Header = styled.header`
  width: 100%;
  background: #333;
  color: #fff;
  padding: 13px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;

  .info {
    display: flex;
    gap: 20px;
    padding: 0px 5px;
  }

  .info div {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .auth {
    display: flex;
    gap: 15px;
  }

  a {
    color: #fff;
    text-decoration: none;
    border: 1px solid #fff;
    padding: 2px 10px;
    border-radius: 5px;
  }
  a:hover {
    color: #fff;
    text-decoration: none;
    border: 1px solid #0000ff;
    padding: 2px 10px;
    background-color: #0000ff;
  }

  @media (max-width: 1024px) {
    .email-info {
      display: none !important;
    }
  }
`;

const TopHeader = () => {
  return (
    <Header>
      <div className="info">
        <div className="email-info">
          <FaEnvelope /> drriteshyd83@gmail.com 
        </div>
        <div>
          <FaMapMarkerAlt /> Vijay Nagar
        </div>
      </div>
      <div className="auth">
        <div className="flex items-center gap-5">
          {/* <FaPhoneAlt /> +91-90740 09424 */}
          <a href="tel:90740 09424">call us now</a>
        </div>
      </div>
    </Header>
  );
};

export default TopHeader;
