import React from 'react';
import Homeabout from '../Aboutus/Homeabout';
import ServiceCardHome from '../Services/ServiceCard';
import Galleryhome from '../Gallery/Galleryhome';
import ContactForm from '../Contact/ContactForm';
import Homecardblog from '../Blogs/Homecardblog';

const Hero = () => {
  return (
    <div>
      <div>
        <div className="relative h-screen flex items-center justify-center ">
          <div className="absolute md:-inset-5 md:-mt-40 -mt-0  md:-mb-20 -mb-0 inset-0 md:bg-contain bg-cover bg-no-repeat bg-center md:bg-home2 bg-mobailhome bg-opacity-75 "></div>
          {/* <div
            data-aos="flip-up"
            className="relative z-10  mt-96 bg-opacity-75 border-2 border-blue border-t-8  p-8 rounded-md shadow-lg text-center"
          >
            <h1 className="text-4xl font-bold mb-4">Dr Ritesh Singh Yadav</h1>
            <p className="text-xl mb-4">Welcome to our clinic</p>
            <a
              href="/contact-us"
              className="bg-blue text-white px-4 py-2 rounded"
            >
              Book An Appointment
            </a>
          </div> */}
        </div>

        <section data-aos="fade-up" id="services" className=" text-center   ">
          <p className="bg-lightblue bg-opacity-20 ">
            <Homeabout />
          </p>
        </section>
        <section id="contact" className="py-12 text-center" data-aos="fade-up">
          <div className="flex justify-center items-center ">
            <h2 className="text-center text-3xl flex justify-center flex-col font-semibold mb-5">
              Services We Offer
              <div className="flex justify-center items-center text-primary py-3">
                <div className="w-20 border-b-2 border-blue"></div>
                <i className="fa-solid fa-circle text-[8px] text-blue"></i>
                <div className="w-20 border-b-2 border-blue"></div>
              </div>
            </h2>
          </div>

          <p>
            <ServiceCardHome />
          </p>
        </section>
        <section id="contact" className="py-12 text-center">
          <div className="flex justify-center items-center ">
            <h2 className="text-center text-3xl flex justify-center flex-col font-semibold mb-5">
              Gallery
              <div className="flex justify-center items-center text-primary py-3">
                <div className="w-20 border-b-2 border-blue"></div>
                <i className="fa-solid fa-circle text-[8px] text-blue"></i>
                <div className="w-20 border-b-2 border-blue"></div>
              </div>
            </h2>
          </div>

          <p>
            <Galleryhome />
          </p>
        </section>
        <section id="contact" className="py-2 text-center">
          <div className="flex justify-center items-center ">
            <h2 className="text-center text-3xl flex justify-center flex-col font-semibold ">
              Blog
              <div className="flex justify-center items-center text-primary py-3">
                <div className="w-20 border-b-2 border-blue"></div>
                <i className="fa-solid fa-circle text-[8px] text-blue"></i>
                <div className="w-20 border-b-2 border-blue"></div>
              </div>
            </h2>
          </div>

          <p>
            <Homecardblog />
          </p>
        </section>
        <section id="contact" className="py-12 text-center">
          <div className="flex justify-center items-center ">
            <h2 className="text-center text-3xl flex justify-center flex-col font-semibold mb-5">
              Contact
              <div className="flex justify-center items-center text-primary py-3">
                <div className="w-20 border-b-2 border-blue"></div>
                <i className="fa-solid fa-circle text-[8px] text-blue"></i>
                <div className="w-20 border-b-2 border-blue"></div>
              </div>
            </h2>
          </div>

          <p>
            <ContactForm />
          </p>
        </section>
      </div>
    </div>
  );
};

export default Hero;
