import React from 'react';

const TermsConditions = () => {
  return (
    <div>
      <div class=" mx-auto px-4 py-8">
        <h1 class="text-3xl font-bold mb-4">Terms and Conditions</h1>

        <p class="mb-4">
          These terms and conditions outline the rules and regulations for the
          use of Dr. Ritesh Yadav Orthopedics' website.
        </p>

        <h2 class="text-2xl font-bold mb-2">1. Acceptance of Terms</h2>

        <p class="mb-4">
          By accessing this website, you agree to be bound by these terms and
          conditions, all applicable laws, and regulations, and agree that you
          are responsible for compliance with any applicable local laws.
        </p>

        <h2 class="text-2xl font-bold mb-2">2. Medical Advice Disclaimer</h2>

        <p class="mb-4">
          The information provided on this website is for general informational
          purposes only. It does not constitute medical advice and should not be
          relied upon as a substitute for professional medical diagnosis or
          treatment.
        </p>

        <h2 class="text-2xl font-bold mb-2">3. Use License</h2>

        <p class="mb-4">
          Permission is granted to temporarily download one copy of the
          materials (information or software) on Dr. Ritesh Yadav Orthopedics'
          website for personal, non-commercial transitory viewing only. This is
          the grant of a license, not a transfer of title, and under this
          license, you may not:
        </p>

        <ul class="list-disc list-inside mb-4">
          <li>Modify or copy the materials;</li>
          <li>
            Use the materials for any commercial purpose, or for any public
            display (commercial or non-commercial);
          </li>
          <li>
            Attempt to decompile or reverse engineer any software contained on
            Dr. Ritesh Yadav Orthopedics' website;
          </li>
          <li>
            Remove any copyright or other proprietary notations from the
            materials; or
          </li>
          <li>
            Transfer the materials to another person or "mirror" the materials
            on any other server.
          </li>
        </ul>

        <p class="mb-4">
          This license shall automatically terminate if you violate any of these
          restrictions and may be terminated by Dr. Ritesh Yadav Orthopedics at
          any time. Upon terminating your viewing of these materials or upon the
          termination of this license, you must destroy any downloaded materials
          in your possession whether in electronic or printed format.
        </p>

        <h2 class="text-2xl font-bold mb-2">4. Limitations</h2>

        <p class="mb-4">
          In no event shall Dr. Ritesh Yadav Orthopedics or its suppliers be
          liable for any damages (including, without limitation, damages for
          loss of data or profit, or due to business interruption) arising out
          of the use or inability to use the materials on Dr. Ritesh Yadav
          Orthopedics' website, even if Dr. Ritesh Yadav Orthopedics or a Dr.
          Ritesh Yadav Orthopedics authorized representative has been notified
          orally or in writing of the possibility of such damage. Because some
          jurisdictions do not allow limitations on implied warranties, or
          limitations of liability for consequential or incidental damages,
          these limitations may not apply to you.
        </p>

        <h2 class="text-2xl font-bold mb-2">5. Revisions and Errata</h2>

        <p class="mb-4">
          The materials appearing on Dr. Ritesh Yadav Orthopedics' website could
          include technical, typographical, or photographic errors. Dr. Ritesh
          Yadav Orthopedics does not warrant that any of the materials on its
          website are accurate, complete, or current. Dr. Ritesh Yadav
          Orthopedics may make changes to the materials contained on its website
          at any time without notice. Dr. Ritesh Yadav Orthopedics does not,
          however, make any commitment to update the materials.
        </p>

        <h2 class="text-2xl font-bold mb-2">6. Links</h2>

        <p class="mb-4">
          Dr. Ritesh Yadav Orthopedics has not reviewed all of the sites linked
          to its website and is not responsible for the contents of any such
          linked site. The inclusion of any link does not imply endorsement by
          Dr. Ritesh Yadav Orthopedics of the site. Use of any such linked
          website is at the user's own risk.
        </p>

        <h2 class="text-2xl font-bold mb-2">
          7. Site Terms of Use Modifications
        </h2>

        <p class="mb-4">
          Dr. Ritesh Yadav Orthopedics may revise these terms of use for its
          website at any time without notice. By using this website you are
          agreeing to be bound by the then current version of these Terms and
          Conditions of Use.
        </p>

        <h2 class="text-2xl font-bold mb-2">8. Governing Law</h2>

        <p class="mb-4">
          Any claim relating to Dr. Ritesh Yadav Orthopedics' website shall be
          governed by the laws of [Your State/Country], without regard to its
          conflict of law provisions.
        </p>

        <p class="mb-4">
          General Terms and Conditions applicable to use of a website.
        </p>
      </div>
    </div>
  );
};

export default TermsConditions;
